@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,700;1,800&family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Mulish", sans-serif;
}

a {
  text-decoration: none;
}

body {
  background-image: url("../media/bg.png");
  background-color: linear-gradient(180deg, #dbe7fa 0%, #f2f6fd 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
}

#main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  max-width: 500px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 100;
  transition: 0.2s all ease-in-out;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.logo {
  width: 300px;
}

.header p {
  font-weight: 900;
  color: #02a9dc;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
}

.shield {
  width: 200px;
  margin-right: -60px;
}

.shield.y-axis {
  animation: y-axis-animation 3s ease-in-out 0s infinite alternate;
}

.input-modal.d-none {
  display: none;
}

.buttons.d-none {
  display: none;
}

.loading-div {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.loading-div.d-none {
  display: none;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 100%;
  height: 100%;
  animation: spin_loading 2s linear infinite;
}

.bg-input-wrapper2 {
  background-color: #3b3b3b98;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.huongdan-title {
  margin: 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid;
  color: #fff;
}

.label2.title {
  font-size: 20px;
}

.label2 {
  color: #fff;
}

.label {
  color: black;
  display: none;
}

#account-label {
  font-weight: 600;
}

.input-wrapper2 {
  position: absolute;
  background-color: #03030385;
  width: 460px;
  padding: 24px 0px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(8, 8, 8) 3px 3px 6px 0px inset, rgrgba(29, 29, 29, 0.5) 3px -3px 6px 1px inset;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

@-webkit-keyframes spin_loading {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin_loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes y-axis-animation {
  from {
    transform: rotateY(30deg);
  }

  to {
    transform: rotateY(-30deg);
  }
}

.button-get-pass {
  width: 80%;
  margin: 6px 0;
}

.button-get-pass:hover {
  opacity: 0.9;
  cursor: pointer;
}

.notice {
  margin-top: 24px;
  width: 96%;
  margin-bottom: 16px;
}

.button-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-style {
  box-shadow: inset 0px 1px 0px 0px #97c4fe;
  background: linear-gradient(to bottom, #00dcff 5%, #0093ab 100%);
  background-color: #0e5cf8;
  border-radius: 24px;
  border: 1px solid #00dcff;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #1570cd;
}

.button-style:hover {
  background: linear-gradient(to bottom, #06b3ce 5%, #056270 100%);
  background-color: #10239f;
}

.button-style:active {
  position: relative;
  top: 1px;
}

.bg-input-wrapper {
  background-color: #3b3b3b98;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.input-wrapper {
  position: absolute;
  background-color: #ffffff;
  width: 365px;
  padding: 24px 0px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.info-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-wrapper input {
  width: 260px;
  margin-bottom: 12px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  background-color: #c9c9c9;
  text-align: center;
}

.input-wrapper input:focus {
  outline: none;
}

.notice-modal {
  width: 100%;
  text-align: center;
  background-color: #ffc8d6;
  line-height: 30px;
  color: #4d0000;
  font-weight: 600;
  margin-bottom: 6px;
  display: none;
}

.success-modal {
  text-align: center;
  display: none;
  padding: 0 12px;
}

.success-modal h1 {
  color: #b42746;
}

.success-modal h2 {
  color: #0691a7;
}

.none-display {
  display: none !important;
}

.flex-display {
  display: flex !important;
}

.block-display {
  display: block !important;
}

.blur-display {
  filter: blur(5px);
  transition: 0.2s all ease-in-out;
}
.close-modal {
  position: absolute;
  top: 0px;
  right: 5px;
  color: #817d70;
  padding: 5px;
  cursor: pointer;
}